/* ==========================================================
 header
========================================================== */
.header {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  background-color: #fff;

  //スクロール時のヘッダー状態
  .fixedheader &{
    box-shadow:0px 4px 15px 0px rgba(0,0,0,0.16);
    .header-logo{
      @media screen and (min-width: 769px){
        top: 11px;
      }
      img.only-pc{
        width: 90px;
      }
    }
    .header-in{
      height: $headerFixedHeight;
    }
    .nav-global,
    .btn-lang{
      top: 15px;
    }
    .mod-mega-menu{
      top: 40px;
    }
    .txt-header{
      margin-top: 7px;
      font-size: 20px;
      font-size: 2rem;
    }
  }

  .fixedheader.fixedSubNavi &{
    box-shadow:0px 1px 1px 0px rgba(0,0,0,0.16);
  }

  @media screen and (min-width: 769px){
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: $headerLineHeight;
      background-color: $colorBlue01;
    }
  }
  .header-in {
    position: relative;
    margin: 0 auto;
    border-top: $headerLineHeight solid $colorBlue01;
    transition: height $headerFixedAnimationSpeed;
    &:before {
      content: "";
      position: absolute;
      top: -$headerLineHeight;
      right: 0;
      width: 168px;
      height: $headerLineHeight;
      background-image: url(/inc/image/bg_line_yellow_pc.png);
      background-color: #fff;
      background-size: 100% auto;
      background-position: 0 0;
      background-repeat: no-repeat;
    }
    @media screen and (min-width: 769px){
      max-width: 1200px;
      height: $headerHeight;
    }
    @media screen and (max-width: 768px){
      height: 60px;
      border-width: 5px;
      &:before {
        top: -5px;
        width: 49px;
        height: 5px;
        background-image: url(/inc/image/bg_line_yellow_sp.png);
      }
    }
  }
  .header-logo {
    transition: top $headerFixedAnimationSpeed;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    display: inline-block;
    img{
      transition: width $headerFixedAnimationSpeed;
    }
    a {
      display: inline-block;
      @include hoverOpacity;
    }
    @media screen and (max-width: 768px){
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }


  //サイト内検索用
  #header-right{
    position: absolute;
    background-color: #fff;
    top: 25px;
    right: 20px;
    z-index: 2;
    text-align: right;
    transition: top $headerFixedAnimationSpeed;

    @media screen and (max-width: 1150px) {
        top: 53px;
    }

    .fixedheader &{
      top: 12px;
    }

    #sitesearch{
      max-width: 24px;
      vertical-align: top;
      border-right: 1px dotted #ccc;
      margin-right: 3px;
      padding-right: 5px;
      &:hover{
        max-width: 100%;
      }

      //サイト内検索
      form{
        display: inline-block;
        input{
          width: 130px;
          font-size: 13px;
          border-radius: 4px;
          border: 1px solid #bbb;
        }
      }
      i{
        text-align: center;
        font-size: 14px;
        width: 20px;
        height: 20px;
      }
    }

    #sitesearch,
    .btn-lang {
      display: inline-block;
      transition: max-width .6s;
      overflow: hidden;
      white-space: nowrap;
      opacity: 1;
    }

  }
  .btn-lang {
    max-width: 100%;
    vertical-align: middle;
    line-height: 1;
  }

  .btn-nav-global {
    position: absolute;
    top: 20px;
    right: 16px;
    width: 25px;
    height: 18px;
    .bar {
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $colorBlue01;
      transition: all .25s ease-in;
      &:nth-child(1) { top: 0; }
      &:nth-child(2) { top: 8px; }
      &:nth-child(3) { bottom: 0; }
    }
    &.is-open {
      .bar {
        &:nth-child(1) { transform: rotate(45deg) translate(4px, 6px); }
        &:nth-child(2) { transform: scaleX(0); }
        &:nth-child(3) { transform: rotate(-45deg) translate(5px, -7px); }
      }
    }
  }

  .txt-header {
    float: right;
    margin: 15px 20px 0 0;
    color: $colorBlue01;
    font-weight: 500;
    font-size: 26px;
    font-size: 2.6rem;

    @media screen and (max-width: 768px){
      float: none;
      margin: 0;
      font-size: 22px;
      font-size: 2.2rem;
      text-align: center;
    }
  }

  //contact form header
  &.contact {
    border-bottom: 1px solid #ccc;
  }
  &.contact .header-in {
    max-width: 1000px;
  }
  @media screen and (max-width: 768px){
    &.contact .header-in {
      height: auto;
      padding: 45px 0 10px;
    }
  }
}
