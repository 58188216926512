/* ==========================================================
 module
========================================================== */
/* tab
---------------------------------------------------------- */
.mod-tab-01 {
  overflow: hidden;
  .list-tab-01 {
    margin-bottom: 40px;
  }
  .tab-content {
    position: relative;
    min-height: 300px;
    padding-top: 40px;

    @media screen and (max-width: 768px) {
      padding-top: 20px;
    }
    .tab {
      display: none;
    }
  }
}
.list-tab-nav-01 {
  background-color: #dadada;
  overflow: hidden;
  border-radius: 5px 5px 0 0;

  @media screen and (min-width: 769px) {
    &.is-affixed{
      z-index: 1000;
      .inner-wrapper-sticky{
        background-color: #eee!important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    border: 1px solid #aaa;
    border-radius: 5px;
  }

  &.col-2-sp,
  &.col-3-sp {
    @media screen and (max-width: 768px) {
      li {
        width: 100%;
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  
  //tabmenuのpulldown用
  &.pulled{
    a{
      border-bottom: none;
    }
  }

  li {
    float: left;
    line-height: 1;
    min-width: 100px;
    &:last-child a{
      @media screen and (max-width: 768px) {
        border: none;
      }
    }
  }

  a {
    position: relative;
    display: inline-block;
    padding: 18px 15px;
    background-color: #354d7c;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid rgba(255, 255, 255, 0.17);
    color: #fff;
    transition: color .6s, background-color .6s;

    @media screen and (max-width: 768px) {
      background-color: #fff;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px dotted #ccc;
      color: $baseFontColor;
    }

    &:hover{
      color: #fff;
    }
    &:before {
      display: none;
      content: "";
      position: absolute;
      left: 50%;
      bottom: 3px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 10px solid #dec100;
      transform: translateX(-50%);
      @media screen and (max-width: 768px) {
        right: 10px;
        left: auto;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.is-active {
      background-color: #fff;
      border-top-color: #c1c1c1;
      border-right-color: #c1c1c1;
      border-left-color: #c1c1c1;
      color: $colorBlue01;
      font-weight: 500;
      &:before {
        display: block;
      }
      @media screen and (max-width: 768px) {
        background-color: #354d7c;
        color: #fff;
      }
    }
    @media screen and (min-width: 769px) {
      &:not(.is-active) {
        @include hoverOpacity;
      }
    }
    @media screen and (max-width: 768px) {
      padding: 15px 5px;
      font-size: 13px;
    }
  }
}
