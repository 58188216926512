/* ==========================================================
 * Fonts
 *==========================================================*/
 @font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 100;
  src: url(/inc/fonts/NotoSans_thin.woff2) format('woff2'), url(/inc/fonts/NotoSans_thin.woff) format('woff'), url(/inc/fonts/NotoSans_thin.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 200;
  src: url(/inc/fonts/NotoSans_light.woff2) format('woff2'), url(/inc/fonts/NotoSans_light.woff) format('woff'), url(/inc/fonts/NotoSans_light.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 300;
  src: url(/inc/fonts/NotoSans_demilight.woff2) format('woff2'), url(/inc/fonts/NotoSans_demilight.woff) format('woff'), url(/inc/fonts/NotoSans_demilight.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: url(/inc/fonts/NotoSans_regular.woff2) format('woff2'), url(/inc/fonts/NotoSans_regular.woff) format('woff'), url(/inc/fonts/NotoSans_regular.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  src: url(/inc/fonts/NotoSans_medium.woff2) format('woff2'), url(/inc/fonts/NotoSans_medium.woff) format('woff'), url(/inc/fonts/NotoSans_medium.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: url(/inc/fonts/NotoSans_bold.woff2) format('woff2'), url(/inc/fonts/NotoSans_bold.woff) format('woff'), url(/inc/fonts/NotoSans_bold.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 900;
  src: url(/inc/fonts/NotoSans_black.woff2) format('woff2'), url(/inc/fonts/NotoSans_black.woff) format('woff'), url(/inc/fonts/NotoSans_black.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 200;
  src: url(/inc/fonts/NotoSerifJP-ExtraLight.otf) format('opentype');
}

