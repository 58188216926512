/* ==========================================================
 layout
========================================================== */

html {
  overflow-y: scroll;
  &.is-locked {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}

/* ==========================================================
 wrapper and container
========================================================== */

.wrapper {
  padding-top: $headerHeight;
  .fixedheader &{
    adding-top: $headerFixedHeight;
  }
  width: 100%;

  @media screen and (min-width: 769px){
    min-width: 1120px;
    overflow: hidden;
  }
  @media screen and (max-width: 768px){
    padding-top: $headerFixedHeight;
    transition: transform 0.2s ease-in;
  }
}
.container {
  @media screen and (min-width: 769px){
    min-width: 1000px;
    width: 1000px;
    margin: 0 auto;
  }
  @media screen and (max-width: 768px){
    padding-right: 10px;
    padding-left: 10px;
  }
}

/* ==========================================================
 contents
========================================================== */

.contents {
  position: relative;
  @media screen and (min-width: 769px){
    margin: 0 auto 100px;
  }
  @media screen and (max-width: 768px){
    margin-bottom: 50px;
  }
}
.contents-layout-01 {
  //何かに影響するかもしれないけど、プルダウンメニューが隠れるので一旦コメアウ
  // overflow: hidden;

  margin-bottom: -100px;
  @media screen and (max-width: 768px){
    margin-bottom: -50px;
  }
  .contents-main {
    @media screen and (min-width: 769px){
      width: 100%;
      clear: both;
      padding-bottom: 100px;
    }
    @media screen and (max-width: 768px){
      padding-bottom: 50px;
    }
  }
  .contents-sub {
    @media screen and (min-width: 769px){
      margin-bottom: 50px;
      width: 100%;
    }
  }
}

.contents-layout-02 {
  overflow: hidden;
  .contents-main {
    @media screen and (min-width: 769px){
      float: right;
      width: 710px;
    }
    @media screen and (max-width: 768px){

    }
  }
  .contents-sub {
    @media screen and (min-width: 769px){
      float: left;
      // width: 260px;
      height: 100vh;
      background: #868b94;
    }
    @media screen and (max-width: 768px){
      display: none;
    }
  }
}


/* ==========================================================
 section
========================================================== */
/* sec-cmn
---------------------------------------------------------- */
/* section last margin */
.sec-cmn-01 {
  margin-bottom: 50px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

section:last-of-type {
  margin-bottom: 0;
}
