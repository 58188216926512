/* ==========================================================
 table
========================================================== */
/* table-cmn-01
---------------------------------------------------------- */

//.article-product .tabは製品詳細内のテーブル用
//.box-news-detail-content tableはニュース詳細用
.table-cmn-01,
.article-product .tab table,
.box-news-detail-content table{
  width: 100%;

  @media screen and (max-width: 768px) {

    &.full {
      width: 100%;
    }
  }

  th {
    padding: 11px 15px;
    background: #f5f5f5;
    border: 1px solid #cdcdcd;
    font-weight: 700;
    text-align: center;
  }
  td {
    padding: 11px 15px;
    border: 1px solid #cdcdcd;
  }
  tr.color-01 {
    background: #ffffcb;
    color: #c14900;
  }
}

//[横にスクロールができます◀▶]のテーブル
table {
  & .scrollable-table-wrapper {
    margin-top: 25px;
  }
  &.no_border{
  	td{
  		border: none;
  	}
  }
}
//←スライドで表の詳細を確認できます→の表示
.box-table-wrapper-sp::before {
    @media screen and (max-width: 768px) {
    content: "←スライドで表の詳細を確認できます→";
    font-size: 90%;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
    text-align: center;
    }  
}

//レスポンシブの際、テーブルの横幅が縮まりすぎて見づらいとき使用
.table-cmn-01-sp {
   @media screen and (max-width: 768px) {
      min-width: 1000px;
    }
}