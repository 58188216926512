/* ==========================================================
 footer
========================================================== */

.footer {
  position: relative;
  width: 100%;
  background-color: #fff;
  transition: transform 0.2s ease-in;
  @include clearfix();

  &.bg-white {
    background-color: #fff;
  }
  @media screen and (min-width: 769px){
    min-width: 1120px;
    background-color: #eee;
  }
  .footer-container {
    @media screen and (min-width: 769px){
      width: 95%;
      margin: 0 auto;
      position: relative;
      .sns-link{
        position: absolute;
        bottom: 10px;
        right: 0;
        li{
          display: inline-block;
          vertical-align: middle;
          &:not(:last-child) {
            margin-right: 18px;
          }
        }
      }
    }
    @media screen and (max-width: 768px){
      padding-right: 18px;
      padding-left: 18px;
      .sns-link{
        text-align: center;
        padding: 18px 0 15px;
        li{
          display: inline-block;
          vertical-align: middle;
          &:not(:last-child) {
            margin-right: 18px;
          }
        }
      }
    }
  }
  .txt-link-footer {
    display: inline-block;
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 500;
    color: #393939;
    line-height: 1.1;
    &:hover {
      color: $colorBlue01;
      text-decoration: underline;
    }
    &.blank {
      padding-right: 20px;
      background-image: url(/inc/image/ico_new_window.png);
      background-size: 12px;
      background-position: right center;
      background-repeat: no-repeat;
    }
    @media screen and (max-width: 768px){
    }
  }
}
.footer-top {
  padding-top: 35px;
  padding-bottom: 20px;
  @media screen and (max-width: 768px){
    padding-bottom: 0;
  }
  .footer-logo {
    display: inline-block;
    margin-bottom: 48px;
    @media screen and (max-width: 768px){
      position: relative;
      left: 50%;
      margin-left: -78px;
      margin-bottom: 35px;
    }
    a {
      display: block;
      width: 183px;
      @include hoverOpacity;
      @media screen and (max-width: 768px){
        width: 157px;
      }
    }
    img { width: 100%; }
  }
}
.footer-nav {
  display: block;
  @media screen and (min-width: 769px) {
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    @media screen and (min-width: 769px){
      margin-right: 30px;
      &:nth-child(1) {
        // width: 165px;
        margin-left: 0;
      }
      &:nth-child(2) {
        // width: 455px;
        .col-01 {
          // width: 235px;
        }
        .col-02 {
          // width: 220px;
        }
      }
      &:nth-child(3) {
        // width: 440px;
        .col-01 {
          // width: 235px;
        }
        .col-02 {
          // width: 205px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 1218px) {
      &:nth-child(3) {
        margin-left: 0;
      }
    }
    @media screen and (max-width: 768px){
      margin-bottom: 20px;
    }
  }
  .title {
    line-height: 1;
    @media screen and (min-width: 769px){
      margin-bottom: 10px;
      padding-bottom: 15px;
      border-bottom: 1px solid #cbcbcb;
    }
  }
  .list-links {
    @media screen and (min-width: 769px){
      float: left;
      margin-bottom: 65px;
      margin-left: 3px;
      margin-right: 30px;
    }
    @media screen and (max-width: 768px){
      display: none;
    }
    li {
      line-height: 1;
    }
    a {
      display: inline-block;
      font-size: 12px;
      font-size: 1.2rem;
      line-height: 2;
      color: #7a7a7a;
      &:hover {
        color: $colorBlue01;
        text-decoration: underline;
      }
    }
  }
}
.footer-links-01 {
  @media screen and (min-width: 769px){
    margin-left: -30px;
    overflow: hidden;
    width: 80%;
  }
  li {
    line-height: 1;
    @media screen and (min-width: 769px){
      float: left;
      margin-left: 30px;
    }
    @media screen and (max-width: 768px){
      margin-bottom: 20px;
    }
  }
}
.footer-bottom {
  overflow: hidden;
  border-top: 1px solid #cbcbcb;
  .footer-container {
    position: relative;
    padding-top: 10px;
  }
  .footer-copyright {
    font-size: 11px;
    font-size: 1.1rem;
    color: #a5a5a5;
    line-height: 1.1;
  }
  @media screen and (min-width: 769px){
    .footer-container {
      @include clearfix;
      height: 70px;
    }
    .footer-links-02 {
      float: left;
    }
    .footer-copyright {
      float: right;
    }
  }
  @media screen and (max-width: 768px){
    .footer-container {
      padding-bottom: 20px;
      &:before {
        right: 0;
        width: 74px;
        background-image: url(/inc/image/bg_line_yellow_02_sp.png);
        background-color: #fff;
      }
    }
    .footer-links-02 {
      margin-bottom: 20px;
      li{
        display: inline-block;
        margin-right: 1em;
      }
    }
    .footer-copyright {
      text-align: center;
    }
  }
}
.footer-links-02 {
  @media screen and (min-width: 769px){
    overflow: hidden;
    margin-left: -40px;
  }
  @media screen and (max-width: 768px){
  }
  li {
    line-height: 1;
    @media screen and (min-width: 769px){
      float: left;
      margin-left: 40px;
    }
  }
  a {
    display: inline-block;
    font-size: 12px;
    font-size: 1.2rem;
    color: #7a7a7a;
    line-height: 1.1;
    &:hover {
      color: $colorBlue01;
      text-decoration: underline;
    }
    @media screen and (max-width: 768px){
      line-height: 2;
    }
  }
}
.footer-links-subTitle {
  font-weight: 600;
}
