/* ==========================================================
 form
========================================================== */
/* table-contact-form
---------------------------------------------------------- */
.table-contact-form {
  width: 100%;
  margin-bottom: 60px;
  border-collapse: collapse;
  text-align: left;

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;

    tr:last-child td {
      border-bottom: 1px solid #ccc;
    }
  }

  th {
    width: 270px;
    padding: 20px 10px 20px 20px;
    background: #f5f5f5;
    border: 1px solid #ccc;
    font-weight: 500;
    vertical-align: middle;

    @media screen and (max-width: 768px) {
      display: block;
      width: auto;
      padding: 10px 15px;
      border-bottom: 0;
    }
  }

  td {
    padding: 15px;
    border: 1px solid #ccc;
    vertical-align: middle;

    @media screen and (max-width: 768px) {
      display: block;
      width: auto;
      padding: 15px;
      border-bottom: 0;
    }
  }

  tr:nth-child(even) td {
    background: #fbfbfb;
    @media screen and (max-width: 768px) {
      background: none;
    }
  }

  .txt-mandatory, .txt-optional {
    position: relative;
    display: block;
    padding-right: 60px;

    &:after {
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: 48px;
      padding: 4px 0 5px;
      background: $colorBlue01;
      border-radius: 3px;
      color: #fff;
      font-weight: 400;
      font-size: 12px;
      font-size: 1.2rem;
      text-align: center;
      line-height: 1;
      transform: translateY(-50%);
      content: "必須";
    }
  }
  .txt-optional {
    &:after {
      background: #fff;
      border: 1px solid $colorBlue01;
      color: $colorBlue01;
      content: "任意";
    }
  }
}

/* box-input-postcode
---------------------------------------------------------- */
.box-input-postcode {
  .box-inner {
    margin-bottom: 12px;
  }
  .list-input {
    display: table;
    float: left;

    @media screen and (max-width: 768px) {
      float: none;
      margin-bottom: 12px;
    }

    li {
      display: table-cell;
      padding: 0 2px;
      vertical-align: middle;
      text-align: center;
    }
  }
  .input-short {
    width: 80px;
  }
  .input-pfwjs {
    width: 90px;
  }
  .input-long {
    width: 500px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .button {
    float: left;
    width: 185px;
    margin: -3px 0 0 15px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 12px;
    font-size: 1.2rem;

    @media screen and (max-width: 768px) {
      float: none;
      width: 194px;
      margin: 0;
    }
  }
}

/* box-input-mail
---------------------------------------------------------- */
.box-input-mail {
  ul {
    display: table;
    width: 100%;
    margin-bottom: 12px;

    @media screen and (max-width: 768px) {
      display: block;
    }

    li {
      display: table-cell;
      vertical-align: middle;
      &:first-child {
        width: 480px;
      }

      @media screen and (max-width: 768px) {
        display: block;
        &:first-child {
          width: 100%;
        }
      }
    }
    .input-long {
      width: 100%;
    }
    .txt-remark {
      padding-left: 15px;
      font-size: 13px;
      font-size: 1.3rem;

      @media screen and (max-width: 768px) {
        padding: 5px 0 0;
        font-size: 11px;
        font-size: 1.1rem;
      }
    }
  }
}

/* form-error-01
---------------------------------------------------------- */
.form-error-01 {
  border-color: #ff0000 !important;
}

/* form-error-text-01
---------------------------------------------------------- */
.form-error-text-01 {
  margin-top: 7px;
  padding-left: 20px;
  background: url(/inc/image/ico_error_01.png) left center no-repeat;
  background-size: 16px 15px;
  color: #eb1b1b;
}

/* form-selectbox-01
---------------------------------------------------------- */
.form-selectbox-01 {
  width: 290px;
  height: 33px;
  padding: 0 0 0 12px;
  border-radius: 3px;
  border: 1px solid #ccc;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

/* form-input-01
---------------------------------------------------------- */
.form-input-01 {
  width: 188px;
  padding: 4px 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: inherit;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #717171;
    opacity: 1;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: #717171;
    opacity: 1;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: #717171;
    opacity: 1;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: #717171;
    opacity: 1;
  }
  &.placeholdersjs {
    color: #717171;
    opacity: 1;
  }
}

/* form-textarea-01
---------------------------------------------------------- */
.form-textarea-01 {
  width: 100%;
  height: 230px;
  padding: 4px 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: inherit;
}

/* radio_gender
---------------------------------------------------------- */
.radio_gender label {
  margin-right: 10px;
}

/* check_interview
---------------------------------------------------------- */
.check_interview label {
  margin-right: 10px;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

/* txt-birthday
---------------------------------------------------------- */
.txt-birthday li {
  display: inline-block;
  margin-right: 5px;
  &:first-child input {
    width: 75px;
  }
  input {
    width: 60px;
    text-align: center;
  }
}

/* radio_gift
  ---------------------------------------------------------- */
  .radio_gift td .gift_box {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }

    label {
      margin-right: 10px;
      @media screen and (max-width: 768px) {
        margin-bottom: 10px;
      }

      .txt-small {
        font-size: 1.1rem;
      }

      .radio_label {
        display: inline-block;
        height: 3em;
        @media screen and (max-width: 768px) {
          display: inline;
          height: auto;
        }
      }
      p {
        align-items: flex-end;
        @media screen and (max-width: 768px) {
          text-align: center;
        }

        img {
          max-width: 100%;
        }
      }
    }
  }
  /* radio_grad
  ---------------------------------------------------------- */
  .radio_grad label {
    margin-right: 10px;
  }