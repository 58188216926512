@charset "UTF-8";

@import "mixin";

@import "fontawesome/fontawesome.css";
@import "fontawesome/fa-solid.css";

@import "./base/reset";
@import "./base/grid";
@import "./base/layout";
@import "./base/fonts";
@import "./base/text";
@import "./base/gridlex/gridlex";

@import "module/header";
@import "module/footer";
@import "module/nav";
@import "module/card";
@import "module/hero";
@import "module/title";
@import "module/box";
@import "module/tab";
@import "module/table";
@import "module/button";
@import "module/list";
@import "module/form";
@import "module/icon";
@import "module/pagetop";

html { font-size: 62.5%; }
body {
  font-family: $defaultFontFamily;
  color: $baseFontColor;
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.7;
  -webkit-text-size-adjust: 100%;

  @media screen and (max-width: 768px){
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.7;
  }
  @media screen and (min-width:0\0) {
    font-family: $defaultFontFamily;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    font-family: $defaultFontFamily;
  }
}

input, textarea, select, button {
  font-family: $defaultFontFamily;
  @media screen and (min-width:0\0) {
    font-family: $defaultFontFamily;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    font-family: $defaultFontFamily;
  }
}


/* ==========================================================
 html5 elements
========================================================== */

article, aside, figure, figcaption, footer, header, nav, section {
  display: block;
}

/* ==========================================================
 base Link
========================================================== */

a {
  color: $baseFontColor;
  text-decoration: none;
  &:visited {}
  &:hover, &:active, &:focus {
    color: $AnchorHoverTextColor;
    text-decoration: none;
  }
}

p > a{
  &:hover, &:active, &:focus {
    color: $colorBlue01;
  }
}


.contents {
  &:before, &:after {
    content: "";
    display: table;
  }
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

/* For IE 6/7
---------------------------------------------------------- */

.clearfix, .contents {
  *zoom: 1;
}



/* tag-cmn-news
---------------------------------------------------------- */
.tag-cmn-news {
  display: inline-block;
  width: 112px;
  padding: 7px 0;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  line-height: 1;

  &.color-01 {
    background: #4055a3;
  }
  &.color-02 {
    background: #8549a4;
  }
  &.color-03 {
    background: #3e8750;
  }
  &.color-04 {
    background: #2787a7;
  }
  &.color-05 {
    background: #e6e6e6;
    color: #4e4e4e;
  }

  &.color-co-01 {
    background: #003399;
  }
  &.color-co-02 {
    background: #097c25;
  }
  &.color-co-03 {
    background: #3e3e3e;
  }
}

/* ==========================================================
 figure
========================================================== */
/* fig-cmn-01
---------------------------------------------------------- */
.fig-cmn-01 {
  img {
    max-width: 100%;
  }
  .txt-caption {
    margin-top: 8px;
    font-size: 14px;
    font-size: 1.4rem;
    text-align: left;
  }
  .box-inner {
    display: inline-block;
  }

  @media screen and (max-width: 768px) {
    .txt-caption {
      font-size: 12px;
      font-size: 1.2rem
    }
  }
}


/* fig-cmn-02
---------------------------------------------------------- */
.fig-cmn-02 {
  display: table;
  width: 100%;

  .image {
    display: table-cell;
    width: 330px;

    @media screen and (max-width: 768px) {
      display: block;
      width: auto;
      max-width: 330px;
      margin: 0 auto;
      text-align: center;
    }

    img {
      max-width: 100%;
    }
  }

  .txt-caption {
    display: table-cell;
    font-size: 18px;
    font-size: 1.8rem;
    vertical-align: middle;
    text-align: center;

    @media screen and (max-width: 768px) {
      display: block;
      padding-top: 5px;
      font-size: 16px;
      font-size: 1.6rem;
      text-align: center;
    }
  }
}


/* ==========================================================
 style
========================================================== */
.s-full-h {
  height: 100%;
}
.s-fw-n {
  font-weight: normal;
}

.s-fw-b {
  font-weight: bold;
}

.s-ta-l {
  text-align: left !important;
}

.s-ta-c {
  text-align: center !important;
}

.s-ta-r {
  text-align: right !important;
}

.s-va-t {
  vertical-align: top !important;
}

.s-va-m {
  vertical-align: middle !important;
}

.s-va-b {
  vertical-align: bottom !important;
}

.s-ov-h {
  overflow: hidden;
}

.s-clear {
  clear: both;
}

.s-hide {
  display: none;
}

.s-txt-hide {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.s-bg-n {
  background: none !important;
}

.s-mt-00 {
  margin-top: 0 !important;
}

.s-mb-00 {
  margin-bottom: 0 !important;
}

.s-mb-05 {
  margin-bottom: 5px !important;
}

.s-mb-10 {
  margin-bottom: 10px !important;
}

.s-mb-15 {
  margin-bottom: 15px !important;
}

.s-mb-20 {
  margin-bottom: 20px !important;
}

.s-mb-35 {
  margin-bottom: 35px !important;
}


.s-ml-00 {
  margin-left: 0 !important;
}

.s-mr-00 {
  margin-right: 0 !important;
}

/* -------------------------------------------------------
Universal Css 記入場所
---------------------------------------------------------*/
/* color */
.clr_red {
  color: red;
}
.clr_crimson {
  color: crimson;
}

/* margin */
.mgn_t_1em {
  margin-top: 1em !important;
}
.mgn_l_1em {
  margin-left: 1em !important;
}

/* font-size */
.fnt_s_dot7em {
  font-size: .7em !important;
}

/* ==========================================================
 rwd switch
========================================================== */
/* pc
---------------------------------------------------------- */

.only-pc {}

.only-sp, .no-pc {
  display: none !important;
}

.no-sp {}

/* sp
---------------------------------------------------------- */
@media screen and (max-width: 768px) {
  .only-pc, .no-sp {
    display: none !important;
  }
  .only-sp, .no-pc {
    display: block !important;
  }
  img {
    &.only-sp, &.no-pc {
      display: inline-block !important;
    }
  }
}


/* ==========================================================
 parts page
========================================================== */
.sec-part-01 {
  margin: 0 auto 40px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.ttl-part-01 {
  margin-bottom: 30px;
  color: $colorBlue01;
  border-bottom: 1px solid #e6e6e6;
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: 700;
}

/* ==========================================================
 parts product page
========================================================== */
/* main-product
---------------------------------------------------------- */
.main-product {
  margin-bottom: 40px;
  @media screen and (max-width: 768px){
    margin-bottom: 30px;
  }
  .slider-wrap {
    position: relative;
    margin-bottom: 20px;
    img {
      width: 100%;
    }
    .slick-dots {
      position: absolute;
      bottom: 18px;
      left: 50%;
      transform: translateX(-50%);
      overflow: hidden;
      li {
        float: left;
        padding: 0 3px;
        outline: none;
        &.slick-active {
          button {
            background-color: #f6ff00
          }
        }
      }
      button {
        display: block;
        width: 8px;
        height: 8px;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 50%;
        outline: none;
        overflow: hidden;
        text-indent: -9999px;
      }
    }
  }
  .heading {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dadada;
    @media screen and (min-width: 769px){
      padding-right: 120px;
    }
    @media screen and (max-width: 768px){
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
    .name-01 {
      display: block;
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 1.4;

      @media screen and (max-width: 768px){
        font-size: 13px;
        font-size: 1.3rem;
      }
    }
    .name-02 {
      display: block;
      font-size: 25px;
      font-size: 2.5rem;
      @media screen and (max-width: 768px){
        font-size: 18px;
        font-size: 1.8rem;
      }
    }
    .code {
      @media screen and (min-width: 769px){
        position: absolute;
        bottom: 0;
        right: 0;
        padding-bottom: 10px;
        font-size: 16px;
        font-size: 1.6rem;
      }
      @media screen and (max-width: 768px){
        font-size: 13px;
        font-size: 1.3rem;
        text-align: right;
      }
    }
  }
  .properties {
    font-size: 18px;
    font-size: 1.8rem;
    color: #003399;
    @media screen and (max-width: 768px){
      font-size: 16px;
      font-size: 1.6rem;
    }
  }
  .list-btns {
    margin-top: 25px;
    padding-right: 10px;
    text-align: right;
    @media screen and (max-width: 768px){
      margin-top: 20px;
    }
    li {
      display: inline-block;
      margin-left: 6px;
      @media screen and (max-width: 768px){
        display: block;
        margin-top: 10px;
      }
    }
  }
}

/* article-product
---------------------------------------------------------- */
.article-product {

  .list-tab-nav-01 a{
    padding: 20px 40px;
  }
  .tab{
    table{
      margin: 10px 0 10px 0;
      @media screen and (max-width: 768px) {
        margin: 0 0 10px 0;
      }
      th,td{
        padding: 6px;
      }
    }
    ol,ul{
      font-size: 85%;
    }
    ol{
      @extend .list-number-01;
    }
    ul{
      @extend .list-bullet-01;
      &.no-list-style li:before {
        content: none;
      }
    }
  }

  .ttl-cmn-01 {
    overflow: hidden;
    // font-size: 20px;
    // font-size: 2rem;
    @media screen and (min-width: 769px){
      margin-bottom: 30px;
    }

    @media screen and (max-width: 768px){
      font-size: 18px;
      font-size: 1.8rem;
    }
  }
  .ttl-cmn-02 {
    overflow: hidden;
    @media screen and (min-width: 769px){
      margin-bottom: 20px;
    }
  }
  .box-text-image {
    overflow: hidden;
    margin-bottom: 30px;
    @media screen and (max-width: 768px){
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 25px;
    }
    .fig-cmn-01 {
      position: relative;
      padding-top: 5px;
      z-index: 1;
      background-color: #fff;
      max-width: 450px;

      @media screen and (max-width: 768px){
        width: 100%;
        order: 2;
        text-align: center;
        img {
          max-width: 100%;
        }
      }
      &.is-right {
        @media screen and (min-width: 769px){
          float: right;
          padding-left: 20px;
        }
      }
      &.is-left {
        @media screen and (min-width: 769px){
          float: left;
          padding-right: 20px;
        }
      }
      .txt-caption {
        margin-bottom: 5px;
        text-align: center;
      }
    }
    .detail {
      @media screen and (max-width: 768px){
        width: 100%;
        order: 1;
        margin-bottom: 15px;
      }
      p {
        line-height: 2;
      }
    }
  }
  .sec-products-01 {
    margin-top: 50px;
    margin-bottom: 50px;
    @media screen and (max-width: 768px){
      margin-bottom: 30px;
    }
  }
  .box-image {
    margin-bottom: 40px;
    text-align: center;

    @media screen and (max-width: 768px){
      margin-bottom: 20px;
    }
    img {
      max-width: 100%;
      margin: 0 auto;
    }
    &.full img {
      width: 100%;
      max-width: none;
    }
  }
  .list-cards {
    overflow: hidden;
    @media screen and (min-width: 769px){
      margin: -20px -5px 0;
    }
    li {
      @media screen and (min-width: 769px){
        float: left;
        width: 25%;
        margin-top: 20px;
        padding: 0 5px;
      }
      @media screen and (max-width: 768px){
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }

      &:nth-child(4n+1) {
        clear: left;
      }
    }
    .image {
      overflow: hidden;
      position: relative;
      z-index: 1;
      margin-bottom: 15px;
      @media screen and (max-width: 768px){
        margin-bottom: 10px;
      }
      img { width: 100%; }
    }
    @media screen and (min-width: 769px){
      a {
        .image img {
          transition: transform 0.2s ease-in-out;
        }
        &:hover .image img {
          transform: scale($imgAnimationAfterScale);
        }
      }
    }
    .title {
      font-size: 16px;
      font-size: 1.1em;
      margin-bottom: 10px;
      font-weight: 500;
      @media screen and (max-width: 768px){
        margin-bottom: 5px;
        font-size: 1.4em;
      }
    }
    .text {
      line-height: 2;
    }
  }

  // parts-2.htmlを汎用ページに適用させるため追記
  .box-detail {
    .heading {
      position: relative;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #dadada;
      @media screen and (min-width: 769px){
        padding-right: 120px;
      }
      @media screen and (max-width: 768px){
        padding-bottom: 5px;
        margin-bottom: 5px;
      }
      .name-02 {
        display: block;
        font-size: 25px;
        font-size: 2.5rem;
        @media screen and (max-width: 768px){
          font-size: 18px;
          font-size: 1.8rem;
        }
      }
    }
  }
}
// Text size
.c-txt-small {
  font-size: 80%;
}