/* ==========================================================
 title
========================================================== */
/* ttl-cmn-01
---------------------------------------------------------- */
.ttl-cmn-01 {
  position: relative;
  margin-top: 5px;
  margin-bottom: 20px;
  padding-bottom: 15px;
  font-size: 30px;
  padding-left: 3px;
  font-size: 2.7rem;
  font-weight: 200;

  &:before, &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background: #e6e6e6;
    content: "";
  }
  &:after {
    width: 90px;
    background: $colorBlue01;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
    font-size: 18px;
    font-size: 1.8rem;
  }
  &>.sub-title{
    font-size: 75%;
  }
  &>.sub-title-xs{
    font-size: 65%;
    @media screen and (max-width: 768px) {
    font-size: 75%;
    }
  }
  &>.not-under-sm {
    display: none !important;
    @media screen and (max-width: 768px) {
    display: block !important;
    }
  }
}

/* ttl-cmn-02
---------------------------------------------------------- */
.ttl-cmn-02 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 17px;
  font-size: 21px;
  font-size: 2.1rem;
  font-weight: 400;

  &.no-dot {
    padding-left: 0;
    &:before {
      display: none;
    }
  }


  &:before {
    position: absolute;
    top: 11px;
    left: 0;
    width: 10px;
    height: 10px;
    background: $colorBlue01;
    border-radius: 50%;
    content: "";
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
    padding-left: 18px;
    font-size: 15px;
    font-size: 1.5rem;

    &:before {
      top: 7px;
      width: 10px;
      height: 10px;
    }
  }
}

.policy .ttl-cmn-02 {
  padding-left: 0;

  &:before {
    content: none;
  }
}

/* ttl-cmn-03
---------------------------------------------------------- */
.ttl-cmn-03 {
  margin-bottom: 35px;
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: 300;
  text-align: center;

  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
    font-size: 18px;
    font-size: 1.8rem;
  }

  span {
    position: relative;
    display: inline-block;
//    padding-bottom: 15px;

//    &:before, &:after {
//      position: absolute;
//      bottom: 0;
//      left: 50%;
//      z-index: 0;
//      width: 90px;
//      height: 3px;
//      margin-left: -45px;
//      background: #fff100;
//      content: "";
//    }
//    &:after {
//      z-index: 1;
//      width: 77px;
//      background: $colorBlue01;
//    }
  }
}
/* ttl-cmn-04
---------------------------------------------------------- */
.ttl-cmn-04 {
	display: inline-block;
	margin-top: 15px;
	border-bottom: 1px solid #003399;
    border-left: 3px solid #003399;
    padding-left: 10px;
	font-weight: bold;
}
/* ttl-cmn-05
---------------------------------------------------------- */
.ttl-cmn-05 {
  margin-top: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.7;
  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
}
