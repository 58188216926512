/* ==========================================================
 grid
========================================================== */
/* grid-list-col2-01
---------------------------------------------------------- */
.grid-list-col2-01 {
  overflow: hidden;
  margin: -30px 0 0 -20px;

  .box-item {
    float: left;
    width: 50%;
    margin-top: 10px;
    margin-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
    &:not(:first-child){
/*      padding-left: 20px;	*/
    }

    @media screen and (min-width: 769px) {
      &:nth-child(2n+1) {
        clear: left;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: -20px 0 0;

    .box-item {
      width: 100%;
      margin-top: 20px;
      padding-left: 0;
    }
  }
}

/* grid-list-col2-02
---------------------------------------------------------- */
.grid-list-col2-02 {
  overflow: hidden;
  margin: -30px 0 0 -20px;

  .box-item {
    float: left;
    width: 50%;
    // margin-top: 30px;
    // padding-left: 20px;
    &:nth-child(2n+1) {
      clear: left;
    }
  }

  @media screen and (max-width: 768px) {
    margin: -15px 0 0 -10px;

    .box-item {
      margin-top: 15px;
      padding-left: 10px;
    }
  }
}

/* grid-list-col3-01
---------------------------------------------------------- */
.grid-list-col3-01 {
  overflow: hidden;
  margin: -30px 0 0 -20px;

  .box-item {
    float: left;
    width: 33.333%;
    margin-top: 30px;
    padding-left: 20px;

    @media screen and (min-width: 769px) {
      &:nth-child(3n+1) {
        clear: left;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: -20px 0 0 -10px;

    .box-item {
      width: 50%;
      margin-top: 20px;
      padding-left: 10px;

      &:nth-child(2n+1) {
        clear: left;
      }
    }
  }
}

/* grid-list-col4-01
---------------------------------------------------------- */
.grid-list-col4-01 {
  // overflow: hidden;
  @include clearfix();
  margin: 0!important;

  .box-item {
    float: left;
    width: 25%;
    margin-bottom: 25px;
    margin-top: 15px;
    padding: 1px;
    // padding-left: 20px;

    @media screen and (min-width: 769px) {
      &:nth-child(4n+1) {
        clear: left;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: -20px 0 0 -10px;

    .box-item {
      width: 50%;
      margin-top: 20px;
      padding-left: 10px;

      &:nth-child(2n+1) {
        clear: left;
      }
    }
  }
}
