/* ==========================================================
 hero
========================================================== */
/* hero-page-title
---------------------------------------------------------- */
.hero-page-title {
  width: 100%;
  margin-bottom: 50px;
  // background: url(/inc/image/bg_hero_title_01.jpg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 800px;
  background-color: #E7E7E7;

  #g01 &{
    background-image: url(../image/bg_hero_about.jpg);
    @media screen and (max-width: 768px) {
      background-position: right 0px;
      min-height: auto;
      background-size: 500px;
    }

  }
  #g02 &{
    background-image: url(../image/bg_hero_products.jpg);
  }
  #g03 &{
    background-image: url(../image/bg_hero_sustainability.jpg);
  }
  #g04 &,
  #news &{
   background-image: url(../image/bg_hero_news.jpg);
  }

  #g05 &{
    background-image: url(../image/bg_hero_support.jpg);
  }

  @media screen and (max-width: 768px) {
    background-position: 0px 0px;
    background-attachment:scroll;

    margin-bottom: 30px;
  }

  .nav-breadcrumb {
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    color: #666;
    a {
      color: #666;
    }

    @media screen and (max-width: 768px) {
      left: 15px;
      right: 15px;
    }
  }

  .hero-page-title-in {
    position: relative;
    // display: table;
    width: 100%;
    // max-width: 1200px;
    min-height: 145px!important;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
      min-height: 130px;
    }
  }

  .box-inner {
    position: absolute;
    width: 100%;
    // padding: 40px 70px;
    // vertical-align: middle;
    top: 50%;
    transform: translateY(-50%);
    // text-align: center;

    @media screen and (max-width: 768px) {
      padding: 15px;
      font-size: 22px;
      font-size: 2.2rem;
    }

    .title {
      position: relative;
      font-size: 34px;
      font-size: 3.4rem;
      font-weight: 200;
      width: 1000px;
      letter-spacing: .1em;
      margin: auto;
      color: darken($colorBlue01,5);

      @media screen and (max-width: 768px) {
        font-size: 25px;
        font-size: 2.5rem;
        width: 100%;
      }
					@media screen and (max-width: 768px) {
        font-size: 2rem;
					}
    }
  }
  &.type-no-bg {
    background: none;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #c1c1c1;
    .box-inner {
      .title {
        color: $colorBlue01;
        font-weight: 500;
      }
    }
    .nav-breadcrumb {
      color: #393939;
      a {
        color: #393939;
      }
    }
  }
}

/* box-contact-link-btm
---------------------------------------------------------- */
.box-contact-link {
  background: #424a58;

  .box-contact-link-in {
    overflow: hidden;
    width: 892px;
    margin: 0 auto;
    padding: 36px 0 25px;

    @media screen and (max-width: 768px) {
      width: auto;
      padding: 0;
    }
  }

  .box-item {
    float: left;
    width: 50%;
    padding: 14px 48px 8px;
    color: #fff;
    &:last-child {
      border-left: 1px solid #868b94;
    }

    @media screen and (max-width: 768px) {
      float: none;
      width: auto;
      padding: 20px 10px;
      &:last-child {
        border-left: none;
        border-top: 1px solid #868b94;
      }
    }

    .title {
      margin-bottom: 12px;
      color: #fff;
      font-size: 22px;
      font-size: 2.2rem;
      font-weight: 500;
      line-height: 1;
      text-align: center;
      @media screen and (max-width: 768px) {
        font-size: 18px;
        font-size: 1.8rem;
      }
    }
    .text {
      min-height: 60px;
      line-height: 1.4;
      @media screen and (max-width: 768px) {
        min-height: 0;
        margin-bottom: 15px;
      }
    }

    .btn-normal-02{
      width: auto;
    }
  }
}
